.main-content{
  height: 100%;
  width: 100%; 
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

body {
   font-family: roboto;
}

@media (min-width: 767.98px) {
  .secondary-navigation{
      display: none;
    } 
}

/* This is for mobile stuff and since it touches everything, it should be at the top*/
@media (max-width: 767.98px) {
  .secondary-navigation{
    display: block !important;
  }
  .sidebar {
    display: none !important;
  }
  .navbar-container {
    padding-left: 15px !important;
    position: relative !important;
  }
  .wrapper {
    padding-left: 0 !important;
    padding-top: 0 !important;
  }
  .footer {
    padding-left: 0 !important;
  }

}

