:root {
    /* Sidebar/Navbar */
    --spinner-color: #F37A24;
    --sidebar-bg-color: #3c3b3b;
    --sidebar-text-color: #dadce1;
    --sidebar-highlight-color: orange;

    /* Main content */
    --main-text-color: rgb(0, 0, 0, .87);
    --main-text-soft-color: gray;
    --main-bg-color: #f2efed;
    --main-card-bg-color: white;
    --main-active-link: #ac570b;
    --place-marker: #e2bfc2; /* currently unused, for calendar/tables */
    --active-item: #ff3d00;  /* currently unused */
    --main-content-header-color: #2f1a07;

    /* Table */
    --table-even-row: #fafafa;
    --table-odd-row: #e9e9e9;
    --table-border: #9c9c9c;

    /* Widget */
    --widget-icon-color: blue;
    --widget-text-color: rgb(242, 139, 45);
    --widget-title-color: rgb(152, 166, 173);

    /* Navigation */
    --navigation-side-padding: 260px;
    --navigation-top-padding: 56px
}